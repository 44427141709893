<script>
import PageHeader from "@/components/page-header";
import OfferCreateModal from "@/views/pages/general/offers/modals/OfferCreate.vue";
import axios from "axios";

export default {
  page: {
    title: "Grid View",
    meta: [{name: "description", content: ''}],
  },
  data() {
    return {
      title: "Grid View",
      items: [
        {
          text: "Candidate Lists",
          href: "/",
        },
        {
          text: "Grid View",
          active: true,
        },
      ],
      searchQuery: null,
      offers: []
    };
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.offers.filter((item) => {
          return (
              item.departure_station_en.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              item.destination_station_en.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              item.duration.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      } else {
        return this.offers;
      }
    },
  },
  methods: {
    async getOffers() {
      try {
        let response = await axios.get(`${process.env.VUE_APP_NOTIFICATION_URL}/offer`);
        this.offers = response.data.offers || [];
      } catch {
        this.offers = [];
      }
    }
  },
  mounted() {
    this.getOffers();
  },
  components: {
    PageHeader,
    OfferCreateModal
  },
};
</script>

<template>
  <PageHeader :title="title" :items="items"/>
  <OfferCreateModal ref="offerCreateModal" @on-offer-created="getOffers()"/>
  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3">
    <b-link class="btn btn-success" @click="this.$refs.offerCreateModal.openModal()">
      <i class="ri-add-line align-bottom me-1"></i> Add Offer
    </b-link>
    <div class="d-md-flex justify-content-sm-end gap-2">
      <div class="search-box ms-md-2 flex-shrink-0 mb-3 mb-md-0">
        <input type="text" class="form-control" id="searchJob"
               placeholder="Search for offers..." autocomplete="off" v-model="searchQuery">
        <i class="ri-search-line search-icon"></i>
      </div>
    </div>
  </div>

  <b-row class="job-list-row" id="candidate-list">

    <b-col xxl="3" md="6" v-for="(data, index) of resultQuery" :key="index">
      <b-card no-body>
        <b-card-body>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column gap-2">
              <div class="d-flex gap-3">
                <span class="text-muted">
                   <i class="ri-map-pin-2-line align-middle text-success fw-bold"></i>
                  From</span>
                <h5 class="fs-16 mb-1">{{ data.departure_station_en }}</h5>
              </div>
              <div class="d-flex gap-3">
                <span class="text-muted">
                  <i class="ri-map-pin-2-line align-middle text-danger fw-bold"></i>
                  To
                </span>
                <h5 class="fs-16 mb-1">{{ data.destination_station_en }}</h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <span class="text-muted">
                  <i class="ri-time-line align-middle fw-bold"></i> Duration</span>
                <h6 class="mb-0">{{ data.duration }} days</h6>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>