<script>
import SelectStations from "@/components/custom/SelectStations.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "OfferCreate",
  emits: ['onOfferCreated'],
  data() {
    return {
      offer: {
        departure: null,
        destination: null,
        duration: ''
      },
      showModal: false,
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    onStationSelect(event) {
      if (event && event.option === 'departure') {
        this.offer.departure = event.value ? event.value : null
      } else if (event && event.option === 'destination') {
        this.offer.destination = event.value ? event.value : null
      } else {
        console.error('Invalid option')
      }
    },
    async submitForm() {
      try {
        await axios.post(`${process.env.VUE_APP_NOTIFICATION_URL}/offer`, {
          departure_station_id: this.offer.departure.value,
          destination_station_id: this.offer.destination.value,
          departure_station_en: this.offer.departure.label,
          destination_station_en: this.offer.destination.label,
          duration: this.offer.duration
        })
        this.$emit('onOfferCreated')
        this.showModal = false
        this.offer = {
          departure: null,
          destination: null,
          duration: ''
        }
        setTimeout(async () => {
          await Swal.fire({
            icon: "success",
            title: "Offer created successfully",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 500)
      } catch {
        await Swal.fire({
          icon: "error",
          title: "Couldn't create offer",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  },
  computed: {
    formIsValid() {
      return this.offer.departure && this.offer.destination && this.offer.duration
    }
  },
  components: {
    SelectStations
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="New Offer" hide-footer centered>
    <form class="row" @submit.prevent="submitForm()">
      <SelectStations
          @on-select="onStationSelect"
          :ratio="[6,6,6,6]"/>
      <div class="col-12">
        <label class="form-label">
          Duration
          <span class="text-danger me-2">*</span>
          <span class="text-muted">(in days)</span>
        </label>
        <input v-model="offer.duration" class="form-control" type="number" step="any" placeholder="Duration in days">
      </div>
      <div class="col-12 mt-3">
        <p class="text-muted">
          I do confirm that the information provided is accurate and I am authorized to create this offer.
          I also understand that this offer will be visible to all visitors of <a class="link-success fw-medium"
                                                                                  href="https://interrail.uz/">interrail.uz</a>
        </p>
        <b-button type="submit" :disabled="!formIsValid" variant="success" class="w-100">Confirm & Submit</b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>